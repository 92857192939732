:root {
    --vertical-rhythm: 2.4rem;

    /* Fonts */
    --font-sans-serif: 'Montserrat', sans-serif;

    /* Colors */
    --rose: #F0CACF;
    --dark-rose: #BC989D; /* sufficient contrast over --aubergine */
    --dark-rose-darker: #946067; /* sufficient contrast over --off-white */
    --charcoal: #2B292C;
    --dark-charcoal: #19171a;
    --orange-lighter: #F09A8E;
    --orange: #F57D6D;
    --dark-orange: #AE4739;
    --dark-orange-darker: #5e2c24;
    --off-white: #f1f1f1;
    --cream: #FCEFE0;
    --aubergine-lighter: #674b7c;
    --aubergine: #41324B;
}

/* CSS Reset */

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

code {
    font-size: 85%;
    opacity: 80%;
    padding: 0 0.25rem;
}

input[type='radio'] {
    accent-color: var(--aubergine-lighter);
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* set up 10px == 1rem sizing */

html {
  font-size: 62.5%;
}

/* Grid */

@mixin container {
    display: grid;
    grid-template-columns: var(--vertical-rhythm) 1fr var(--vertical-rhythm) 1fr var(--vertical-rhythm) 1fr var(--vertical-rhythm);
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;

    @media screen and (min-width: 720px) {
        grid-template-columns: calc(var(--vertical-rhythm) * 3) 1fr calc(var(--vertical-rhythm) * 3) 1fr calc(var(--vertical-rhythm) * 3) 1fr calc(var(--vertical-rhythm) * 3);
    }

}

.container {
    @include container;
}


/* Global */

body {
    background: var(--aubergine);
    color: var(--cream);
    font-family: 'Lora', serif;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: var(--vertical-rhythm);
}

h1 {
    font-family: var(--font-sans-serif);
    font-size: calc(var(--vertical-rhythm) * 2.4);
    font-weight: 300;
    hyphens: manual;
    line-height: calc(var(--vertical-rhythm) * 2.4);
    margin-bottom: var(--vertical-rhythm);
    text-transform: uppercase;
    overflow-wrap: break-word;
}

h2 {
    font-size: var(--vertical-rhythm);
    font-weight: 700;
    hyphens: manual;
    line-height: calc(var(--vertical-rhythm) * 1.3);
    margin-bottom: calc(var(--vertical-rhythm) * 0.5);
    overflow-wrap: break-word;
}

h3 {
    font-weight: 700;
    margin-bottom: calc(var(--vertical-rhythm) / 2);
}

h4 {
    color: var(--dark-rose);
    font-family: var(--font-sans-serif);
    font-size: calc(var(--vertical-rhythm) / 1.6);
    font-weight: 500;
    letter-spacing: 0.2rem;
    line-height: var(--vertical-rhythm);
    margin-bottom: calc(var(--vertical-rhythm) / 2);
    text-transform: uppercase;
}

p {
    margin-bottom: var(--vertical-rhythm);
}

p + p {
    margin-top: calc(var(--vertical-rhythm) / 2 * -1);
}

input {
    border-radius: 0; /* Override iOS */
    font-family: 'Lora', serif;
    font-size: 1.8rem;
    font-weight: 300;
    line-height: var(--vertical-rhythm);
}

.submit,
input[type=submit] {
    background: var(--orange);
    border: 0 none transparent;
    border-radius: 0; /* Override iOS */
    color: var(--charcoal);
    font-family: var(--font-sans-serif);
    font-size: 1.4rem;
    font-weight: 500;
    line-height: var(--vertical-rhythm);
    padding: 1rem 2rem;
    text-transform: uppercase;
}

.submit:hover,
.submit:focus,
.submit:active,
input[type=submit]:hover,
input[type=submit]:focus,
input[type=submit]:active {
    background-color: var(--dark-charcoal);
    color: var(--off-white);
    cursor: pointer;
}

label {
    font-weight: 700;
    margin-bottom: calc(var(--vertical-rhythm) / 2);
}

figure {
    margin-bottom: var(--vertical-rhythm);
}

figcaption p {
    font-style: italic;
    margin-top: var(--vertical-rhythm);

    &:last-of-type {
        margin-bottom: 0;
    }
}

a,
a:link,
a:visited {
    color: var(--orange);
    text-decoration: none;
}

a:hover,
a:focus,
a:active {
    color: var(--orange-lighter);
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

hr {
    background: var(--orange);
    border: 0 none transparent;
    height: 2px;
    margin: 2rem auto 5rem;
    width: 10rem;
}

/* layout helper class */

.mb-0 {
    margin-bottom: 0 !important
}

.mt-0 {
    margin-top: 0 !important;
}

[hidden] {  // from https://tailwindcss.com/docs/screen-readers
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.video-wrapper {
    margin-bottom: var(--vertical-rhythm);
}

/* Header */

header.bio {
    align-items: center;
    background: var(--charcoal) url('header.svg') no-repeat center center;
    background-blend-mode: multiply;
    background-size: cover;
    display: flex;
    height: 76vh;
	min-height: 300px;
}

/* also applies to the lower bio */
.bio h1,
.bio h2,
.bio p {
    grid-column-start: 2;
    grid-column-end: span 5;
}

header.bio h1 {
    color: var(--cream);
    font-size: calc(var(--vertical-rhythm) * 3);
    font-weight: 500;
    line-height: calc(var(--vertical-rhythm) * 3);
    margin-top: var(--vertical-rhythm);
}

header.bio h1 .fn {
    display: block;
    padding-left: 0.9rem;
}


header.bio h1 .asterisk {
    color: var(--orange);
    font-weight: 300;
}

header.bio h2 {
    color: var(--rose);
    font-family: var(--font-sans-serif);
    font-size: var(--vertical-rhythm);
    font-weight: 500;
    hyphens: manual;
    letter-spacing: 0.1rem;
    line-height: calc(var(--vertical-rhythm) * 1.3);
    margin-bottom: calc(var(--vertical-rhythm) * 0.7);
    overflow-wrap: break-word;
    text-transform: uppercase;
}

header.bio p {
    color: var(--cream);
}

@media screen and (min-height: 800px) {
    header.bio {
        height: 60vh;
    }
}

@media screen and (min-width: 720px) {
    header.bio {
        height: 46vh;
    }

    header.bio h1 .fn {
        display: inline;
        padding-left: 0;
    }
}

@media screen and (min-width: 1024px) {
    header.bio {
        height: 56vh;
    }

    header.bio p {
        grid-column-end: span 3;
    }
}

/* Homepage: Cookie Dialog */

#homepage div[role="dialog"] {
    position: static !important; /* Override the media query as well */
}

/* Homepage: Case Studies */

.case-studies-row {
    grid-template-rows: auto;
}

.case-studies-row:first-of-type {
    padding-top: calc(var(--vertical-rhythm) * 2);
}

.case-studies-row article {
    margin-bottom: calc(var(--vertical-rhythm) * 2);
    grid-column-end: 7;
    grid-column-start: 2;
}

.case-studies-row h3 {
    color: var(--rose);
    font-size: var(--vertical-rhythm);
    line-height: calc(var(--vertical-rhythm) * 1.2);
    margin-bottom: calc(var(--vertical-rhythm) * 0.8);
}

@media screen and (min-width: 768px) {
    #homepage main {
        padding-bottom: calc(var(--vertical-rhythm) * 2);
        padding-top: calc(var(--vertical-rhythm) * 2);
    }

    .case-studies-row {
        grid-template-rows: calc(var(--vertical-rhythm) * 3)  calc(var(--vertical-rhythm) * 3) auto;
        padding-bottom: 0;
        padding-top: 0;
    }

    .case-studies-row:first-of-type {
        padding-top: var(--vertical-rhythm);
    }

    .case-studies-row article {
        margin-bottom: 0;
        position: relative;
    }

    .case-studies-row article:nth-child(1n) {
        grid-column-end: 3;
        grid-column-start: 2;
        grid-row-start: 1;
        grid-row-end: 4;
    }

    .case-studies-row article:nth-child(2n) {
        grid-column-end: 5;
        grid-column-start: 4;
        grid-row-start: 3;
        grid-row-end: 4;
    }

    .case-studies-row article:nth-child(3n) {
        grid-column-end: 7;
        grid-column-start: 6;
        grid-row-start: 2;
        grid-row-end: 4;
    }
}

@media screen and (min-width: 1024px) {
    #homepage main {
        padding-bottom: calc(var(--vertical-rhythm) * 3);
        padding-top: calc(var(--vertical-rhythm) * 3);
    }
}

/* Homepage: Arrow Buttons */

.button {
    align-items: center;
    display: flex;
    font-family: var(--font-sans-serif);
    font-size: 1.4rem;
    font-weight: 500;
    gap: 2.8rem;
    line-height: var(--vertical-rhythm);
    text-transform: uppercase;

    span {
        background: url('arrow.svg') no-repeat left center;
        filter: invert(69%) sepia(64%) saturate(3069%) hue-rotate(317deg) brightness(103%) contrast(92%);  /* orange */
        flex-shrink: 0;
        height: 2rem;
        width: 2.4rem;
    }
}

.button:hover,
.button:focus,
.button:active {
    span {
        background-position: right center;
        filter: invert(66%) sepia(24%) saturate(729%) hue-rotate(319deg) brightness(102%) contrast(88%); /* orange-lighter */
    }
}

/* Testimonial */

.testimonial {
    blockquote {
        display: flex;
        flex-direction: column-reverse;
        grid-column-start: 2;
        grid-column-end: 7;
        margin: var(--vertical-rhythm) 0 calc(var(--vertical-rhythm) * 3);
        position: relative;

        > div {
            padding-top: var(--vertical-rhythm);
        }

        cite {
            float: right;
            font-size: 1rem;
            font-style: italic;
            line-height: 100%;
            margin-top: var(--vertical-rhythm);
            text-align: right;
            text-transform: uppercase;

            .org {
                display: block;
            }
        }

        img {
            align-self: flex-end;
            max-width: 100%;
            mix-blend-mode: screen;
            object-fit: contain;
            opacity: 50%;
        }

        p {
            background: url('pullquote.svg') no-repeat right bottom;
            background-size: 100%;
            font-family: "Source Sans 3", sans-serif;
            font-optical-sizing: auto;
            font-size: 2.4rem;
            font-weight: 900;
            line-height: 120%;
            margin-bottom: 0;
            margin-right: 3rem;
            padding-bottom: 5rem;
            padding-right: 7rem;
            text-align: right;
        }
    }
}

@media screen and (min-width: 441px) {
    .testimonial blockquote {
        margin: calc(var(--vertical-rhythm) * 2) 0 calc(var(--vertical-rhythm) * 3);
    }

    .testimonial blockquote > div {
        border-top: 1px solid var(--off-white);
    }
}

@media screen and (min-width: 500px) {
    .testimonial blockquote p {
        max-width: 450px;
        padding-right: 8rem;
    }
}

@media screen and (min-width: 700px) {
    .testimonial {
        blockquote {
            border-bottom: 1px solid var(--off-white);
            flex-direction: row;
            justify-content: space-evenly;
            padding-top: calc(var(--vertical-rhythm) * 2);

            > div {
                border-top: 0 none transparent;
                max-width: 545px;
                padding-bottom: var(--vertical-rhythm);
                padding-top: 0;
            }

            cite {
                float: none;
                left: unset;
                text-align: left;
            }

            p {
                margin-right: 0;
                padding-bottom: 4rem;
                padding-right: 7rem;
            }

            img {
                border-bottom: 0 none transparent;
                flex-shrink: 0;
                flex-grow: 0;
                margin-bottom: 0;
                height: 211px;
                margin-right: -6rem;
                margin-top: 0;
                width: 280px;
            }
        }
    }
}

@media screen and (min-width: 700px) {
    .testimonial {
        blockquote {
            p {
                padding-bottom: 5rem;
                padding-right: 9rem;
            }
        }
    }
}

@media screen and (min-width: 900px) {
    .testimonial {
        blockquote {
            img {
                height: 297px;
                width: 394px;
            }
        }
    }
}

/* Homepage: Lower bio */

aside.bio {
    background: var(--aubergine-lighter) image-set(url('/static/cycling.webp') type('image/webp'), url('/static/cycling.jpg') type('image/jpeg')) no-repeat center 20%;
    background-size: cover;
    margin-bottom: 0;
    margin-top: var(--vertical-rhythm);
    padding: calc(var(--vertical-rhythm) * 2) 0;
}

aside.bio .bio-text {
    grid-column-end: 7;
    grid-column-start: 2;
    padding: var(--vertical-rhythm);
}

aside.bio span {
    background: rgba(45, 35, 47, 0.9);
    color: var(--cream);
    display: inline-block;
    padding: 0.2rem 0.4rem;
}

aside.bio span.asterisk {
    color: var(--orange);
    font-family: var(--font-sans-serif);
    font-weight: 400;
}

aside.bio span.stanza-1 {
    margin-left: -1rem;
    margin-right: 2rem;
}

aside.bio span.stanza-3 {
    margin-left: 2rem;
    margin-right: -1rem;
}

aside.bio a {
    text-decoration: underline;
}

@media screen and (min-width: 450px) {
    aside.bio .bio-text {
        grid-column-end: 6;
    }
}

@media screen and (min-width: 580px) {
    aside.bio .bio-text {
        grid-column-end: 4;
    }
}

@media screen and (min-width: 720px) {
    aside.bio {
        min-height: 56rem;
    }

    aside.bio .bio-text {
        grid-column-end: 4;
    }
}

footer.footer {
    background: var(--off-white);
}

.footer-text {
    grid-column-end: 7;
    grid-column-start: 2;

    &:first-of-type {
        padding-top: calc(var(--vertical-rhythm) * 3);
    }

    &:last-of-type {
        padding-bottom: calc(var(--vertical-rhythm) * 5);
    }

    a.button {
        margin-top: var(--vertical-rhythm); /* a.button otherwise removes the top margin at narrow screen sizes but in this context the touch area is too small */
    }
}


.footer-text + .footer-text {
    padding-top: calc(var(--vertical-rhythm) * 2);
}


@media screen and (min-width: 720px) {
    .footer-text {
        padding-bottom: calc(var(--vertical-rhythm) * 2);
    }

    .footer-text:nth-of-type(1) {
        grid-column-start: 2;
        grid-column-end: 3;
    }

    .footer-text:nth-of-type(2) {
        grid-column-start: 4;
        grid-column-end: 5;
    }

    .footer-text:nth-of-type(3) {
        grid-column-start: 6;
        grid-column-end: 7;
    }

    .footer-text + .footer-text {
        padding-top: calc(var(--vertical-rhythm) * 3);
    }
}

footer.footer {
    background: var(--charcoal);
}

/* Homepage: Theme Switcher */
#theme-switch {
    border-bottom: 1px dotted var(--aubergine-lighter);
    border-top: 1px dotted var(--aubergine-lighter);
    grid-column-end: 7;
    grid-column-start: 2;
    margin-bottom: calc(var(--vertical-rhythm) * 2);
    margin-top: calc(var(--vertical-rhythm) * 2);
    padding-bottom: calc(var(--vertical-rhythm) * 2);
    padding-top: calc(var(--vertical-rhythm) * 2);

    .button {
        gap: 1rem;
        padding-right: 0;
    }
}

@media screen and (min-width: 500px) {
    #theme-switch {
        margin-bottom: var(--vertical-rhythm);
        margin-top: var(--vertical-rhythm);
        padding-bottom: var(--vertical-rhythm);
        padding-top: var(--vertical-rhythm);

        .button {
            padding-right: calc(var(--vertical-rhythm) / 2);
        }
    }

    #theme-switch,
    #theme-switch ul {
        align-items: center;
        display: flex;
        gap: 1.5rem;

        h2 {
            margin-bottom: 0;
            margin-right: calc(var(--vertical-rhythm) - 0.5rem); // legal uses 1rem + vertical rhythm, here the gap is 1.5rem, so subtract 0.5rem
        }

        label {
            margin-bottom: 0;
        }
    }
}

/* Homepage: Legalese */
#legal {
    grid-column-end: 7;
    grid-column-start: 2;
    margin-bottom: calc(var(--vertical-rhythm) * 3);

    a {
        text-decoration: underline;
    }
}

@media screen and (min-width: 500px) {
    #legal {
        align-items: center;
        display: flex;
        gap: calc(1rem + var(--vertical-rhythm));
        margin-top: var(--vertical-rhythm);

        p {
            margin: 0;
        }
    }
}

/* Global: Dialogues */
div[role="dialog"] {
    background: var(--aubergine-lighter);
    bottom: 0;
    padding-bottom: calc(var(--vertical-rhythm) * 2);
    padding-top: calc(var(--vertical-rhythm) * 1.5);
    position: sticky;
    width: 100%;

    > div {
        @include container;

        > * {
            grid-column-start: 2;
            grid-column-end: 7;
        }
    }

    a {
        text-decoration: underline;
    }

    button + button {
        margin-left: calc(var(--vertical-rhythm) / 4);
    }

    details {
        margin-bottom: var(--vertical-rhythm);
    }

    details[open] summary {
        margin-bottom: var(--vertical-rhythm);
    }

    li {
        list-style-position: inside;
        list-style-type: circle;
        margin-bottom: calc(var(--vertical-rhythm) / 2);
        margin-left: var(--vertical-rhythm);
        text-indent: calc(-1 * var(--vertical-rhythm));
    }

    summary {
        font-weight: 700;
    }

    ul {
        margin-bottom: var(--vertical-rhythm);
    }
}

@media screen and (min-height: 780px) {
    div[role="dialog"] {
        position: fixed;
    }
}

div[role="dialog"].closed {
    height: 0;
    padding: 0;
    opacity: 0;
    transition: height 0.25s ease-in-out, opacity 0.1s ease-in, padding 0.25s ease-in-out;
}

/* Case Study Detail Pages, Privacy */
.narrow-page-container {
    @include container;

    .narrow-page-content {
        grid-column-end: 7;
        grid-column-start: 2;
    }
}

/* Case Study Detail Pages */
.narrow-page-container nav {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-top: 0;
    position: fixed;
    right: 0;
    top: var(--vertical-rhythm);
    transition: padding-top 200ms linear;
    z-index: 100;
}

.narrow-page-container nav a div {
    background: url('close.svg') no-repeat center center;
    filter: invert(69%) sepia(64%) saturate(3069%) hue-rotate(317deg) brightness(103%) contrast(92%);  /* orange */
    height: 32px;
    margin-right: var(--vertical-rhythm);
    width: 32px;
}

.narrow-page-container nav a:hover div,
.narrow-page-container nav a:focus div {
    filter: invert(66%) sepia(24%) saturate(729%) hue-rotate(319deg) brightness(102%) contrast(88%); /* orange-lighter */
    height: 28px;
    margin-bottom: 2px;
    margin-top: 2px;
    margin-right: calc(var(--vertical-rhythm) + 2px);
    width: 28px;
}

.narrow-page-container h1 {
    margin-top: calc(var(--vertical-rhythm) * 3);
    margin-right: calc(var(--vertical-rhythm) / 3); /* forces 4c Sports to break while not breaking Athletics */
}

.narrow-page-container .headerlink {
    font-family: var(--font-sans-serif);
    font-size: 1.5rem;
    font-weight: normal;
    line-height: 1.5rem;
    padding-left: 0.5rem;
    vertical-align: super;
}

/* .details and .sub-details both sit within .case-study-detail and serve to wrap .column-single */
/* .details sets up text at the top of the case study while .sub-details supports a caption within */
/* imgs should exist outside of .sub-details > .column-single to go 100% width as .column-single is 88% width */

.details {
    margin: calc(var(--vertical-rhythm) * 2) 0 calc(var(--vertical-rhythm) * 3);
}

.details div h2:not(:first-of-type) {
    margin-top: calc(var(--vertical-rhythm) * 2);
}

.sub-details p a,
.sub-details .bulleted-list a,
.details p a,
.details .bulleted-list a {
    text-decoration: underline;
}

.case-study-detail div:not(.column-single) {
    /* exclude .column-single which wraps a p which already has a bottom margin */
    display: flex;
    margin-bottom: var(--vertical-rhythm);
    max-width: 1024px;
    overflow: hidden;
}

.case-study-detail div.space-out {
    column-gap: var(--vertical-rhythm);
}

.case-study-detail img {
    height: auto;
    max-width: 100%;
}

.case-study-detail div.two-up img {
    max-width: 50%;
}

.case-study-detail div.two-up.space-out img {
    max-width: calc((100% - var(--vertical-rhythm)) / 2);
}
.case-study-detail div.three-up.space-out img {
    max-width: calc((100% - (var(--vertical-rhythm) * 2)) / 3);
}

.bulleted-list {
    list-style: circle;
    margin-bottom: var(--vertical-rhythm);
    margin-left: var(--vertical-rhythm);
}

.bulleted-list li {
    margin-bottom: calc(var(--vertical-rhythm) / 2);
}

video {
    max-height: 100%;
    object-fit: fill;
    width: 100%;
}

.case-study-detail div.light-background {  // override specificity
    background-color: var(--off-white);

    // double the bottom margin while removing it from the figure
    margin-bottom: calc( 2 * var(--vertical-rhythm) );

    figure {
        margin-bottom: 0;
    }
}

.narrow-page-container h1 {
    color: var(--rose);
}

@media screen and (min-width: 550px) {
    .narrow-page-container header h1 {
        padding-right: calc(32px + (var(--vertical-rhythm) * 2)); /* keep X from overlapping */
    }
}

@media screen and (min-width: 720px) {
    .narrow-page-container nav {
        position: sticky;
        top: calc(var(--vertical-rhythm) * 2);
        right: var(--vertical-rhythm);
    }

    .narrow-page-container nav.account-for-dialog {
        padding-top: var(--vertical-rhythm);
    }

    .narrow-page-container h1 {
        margin-top: 0;
    }
}

@media screen and (min-width: 800px) {
    /* once we have space for it, switch to a 2-col layout */
    .details {
        display: flex;
        column-gap: var(--vertical-rhythm);
    }

    figcaption,
    .details div.column-single,
    .sub-details div.column-single {
        width: 88%;
    }
}

/* Login */
.auth-form {
    grid-column-end: 7;
    grid-column-start: 2;
    padding: calc(var(--vertical-rhythm) * 4) 0 calc(var(--vertical-rhythm) * 2);
}

#login-form input,
#login-form label {
    display: block;
}

/* Privacy */
#privacy main {
    a {
        text-decoration: underline;
    }

    ul {
        margin-bottom: var(--vertical-rhythm);

        li {
            list-style-position: inside;
            list-style-type: circle;
            margin-bottom: calc(var(--vertical-rhythm) / 2);
            margin-left: var(--vertical-rhythm);
            text-indent: calc(-1 * var(--vertical-rhythm));
        }
    }
}

/* Light Mode */
@mixin light-mode {
    body {
        background: var(--off-white);
        color: var(--charcoal);
    }

    a,
    a:link,
    a:visited {
        color: var(--dark-orange);
    }

    a:hover,
    a:focus,
    a:active {
        color: var(--dark-orange-darker);
    }

    h4 {
        color: var(--dark-rose-darker);
    }

    input[type='radio'] {
        accent-color: var(--aubergine);
    }

    aside.bio span {
        background: rgba(255, 255, 255, 0.9);
        color: var(--charcoal);
    }

    header.bio {
        background-color: var(--rose);
        background-blend-mode: normal;
    }

    header.bio h1 {
        color: var(--charcoal);
    }

    header.bio h2 {
        color: var(--charcoal);
    }

    header.bio p {
        color: var(--charcoal);
    }

    footer.footer {
        background: transparent;
    }

    .button span {
        filter: invert(38%) sepia(31%) saturate(2945%) hue-rotate(337deg) brightness(73%) contrast(79%); /* dark orange */
    }

    .button:hover,
    .button:focus,
    .button:active {
        span {
            filter: invert(12%) sepia(68%) saturate(1262%) hue-rotate(335deg) brightness(103%) contrast(84%); /* dark orange-darker */
        }
    }

    .submit:hover,
    .submit:focus,
    .submit:active,
    input[type=submit]:hover,
    input[type=submit]:focus,
    input[type=submit]:active {
        background-color: var(--dark-orange);
    }

    .narrow-page-container h1 {
        color: var(--charcoal);
    }

    .narrow-page-container nav a div {
        filter: invert(38%) sepia(31%) saturate(2945%) hue-rotate(337deg) brightness(73%) contrast(79%); /* dark orange */
    }

    .narrow-page-container nav a:hover div,
    .narrow-page-container nav a:focus div {
        filter: invert(12%) sepia(68%) saturate(1262%) hue-rotate(335deg) brightness(103%) contrast(84%); /* dark orange-darker */
    }

    .case-studies-row h3 {
        color: var(--charcoal);
    }

    .light-bg {
        /* used to differentiate light-on-light images from the background */
        border-bottom: 1px solid white
    }

    .testimonial blockquote {
        border-bottom-color: var(--orange-lighter);
    }

    .testimonial blockquote img {
        mix-blend-mode: multiply;
    }

    div[role="dialog"] {
        background: var(--dark-rose-darker);
        color: white;
    }

    div[role="dialog"] a {
        color: white;
    }

    div[role="dialog"] .submit {
        background-color: var(--off-white);
        color: var(--charcoal);
    }

    div[role="dialog"] .submit:hover,
    div[role="dialog"] .submit:focus,
    div[role="dialog"] .submit:active {
        background-color: var(--charcoal);
        color: var(--off-white);
    }
}

@media screen and (prefers-color-scheme: light) {
    @include light-mode;
}

html[data-theme="light"] {
    @include light-mode;
}
